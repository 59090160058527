import React from "react"
import Layout from "../../components/layout";
import Seo from "../../components/SEO/Seo"
import "../../styles/servicios.scss"



export default function Servicios(props) {

    return (
            <Layout>
                <Seo title="Consultoría & estrategia" pathname={props.location.pathname}/>
                <div className="cuerpo-servicios">
                    <section className="container primera-seccion cabecera-servicios">
                        <div className="caja-titulo">
                            <h1>Consultoría & estrategia</h1>
                        </div>
                        <div className="caja-subtitulo">
                            <p className="destacado">¡Sumérgete y bucea como nunca con tu negocio!. Entender en qué oceanos se mueve tu cliente te ayudará a nadar hacia ellos. Piénsalo, ¿y bien?¿y si lo hacemos juntos? Porque nadie se lanza al mar sin saber hacia dónde nadar o a dónde quiere llegar.</p>
                        </div>
                    </section>               
                    <section className="container textos-servicio">
                        <div className="columna-foto" style={{backgroundImage: "url(/img/consultoria-opt.jpg)"}}></div>
                        <div className="columna-texto">
                            <p>Lo primero que haremos juntos será pensar, 
                            hablar y conocer tu negocio para diseñar tu
                            estrategia de marketing. Antes todo era diferente.
                            <br/>
                            Se nadaba en medios más convencionales y reservados 
                            a grandes compañías con muchos recursos. El mapa se ha hecho
                            grande y accesible. El marketing digital provoca que todos 
                            podamos lucir palmito para atraer clientes y generar confianza.
                            </p>
                            <h3>Consultoría y estrategia</h3>
                            <p>Hacer branding de tu marca no es sólo tener un logo. 
                            Nuestro equipo conseguirá reflejar una identidad 
                            diferencial y poderosa.</p>
                            <h3>Branding e identidad corporativa</h3>
                            <p>Sí, estamos para esto. Para acompañarte y ayudarte.
                            Aportándote conocimiento y herramientas 
                            con las que construir el mood de tu negocio.</p>
                            <h3>Comunicación y retargeting</h3>
                            <p>Hacer llegar tu mensaje, que guste,
                            que te entiendan y que sea motivante:
                            tienen que contactar contigo y comprarte.
                            Queremos que crezcas y que saques músculo.</p>
                        </div>
                    </section>
                </div>
    </Layout>
    )
}